import eventsHandler from '@/services/common/eventsHandler.js'

class hairColorForm extends eventsHandler {
  constructor() {
    super()
    this._hairColor = {}
    this._valid = false
  }

  get hairColor() {
    const hairColor = {
      ...this._hairColor
    }
    return hairColor
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._hairColor.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetHairColor() {
    this._hairColor = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {hairColor: {}})
  }

  updateField(key, value) {
    this._hairColor[key] = value
    this._executeCallbacksOf('update', {hairColor: this._hairColor})
    this._executeCallbacksOf('update-' + key, {key: this._hairColor[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(hairColor) {
    this._hairColor = hairColor
    this._executeCallbacksOf('update', {hairColor: this._hairColor})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new hairColorForm()